import React from "react";
import { Button, Stack, Typography, Box } from "@mui/joy";

import Meta from "../Meta";
import { useMobileMode } from "../Responsive";
import details from "../../assets/Details";
import { Link } from "react-router-dom";
import { IoIosReturnLeft } from "react-icons/io";
import Credits from "components/sections/Credits";

export default function About() {
  const mobile = useMobileMode();

  return (
    <Stack>
      <Stack
        width="100vw"
        position="relative"
        overflow="hidden"
        sx={{
          paddingLeft: "var(--nav-safe-area-inset-left)",
          paddingBottom: "var(--nav-safe-area-inset-bottom)",
        }}
      >
        <Meta title={`About ${details.name.first}`} />
        <Stack padding={mobile ? "1rem" : "5rem"} gap={2} alignItems="start">
          <Stack marginBottom="min(10vw, 3rem)" marginTop={mobile ? "10vw" : 0}>
            <Typography
              level="display1"
              fontSize="clamp(3rem, 15vw, 10rem)"
              fontWeight={200}
              lineHeight={1}
              sx={{
                position: "relative",
                marginLeft: "0.25em",
                marginBottom: "0.05em",
              }}
            >
              <Typography
                level="h2"
                sx={{
                  position: "absolute",
                  left: "-0.6em",
                  bottom: "1.7em",
                  transform: "translateX(-50%) rotate(-90deg)",
                  fontSize: "clamp(0.5rem, 3vw, 2rem)",
                }}
              >
                RAG
              </Typography>
              Team
            </Typography>
            <Typography
              level="display1"
              fontSize="clamp(3rem, 15vw, 10rem)"
              fontWeight={300}
              lineHeight={0.6}
              zIndex={1}
              fontFamily={'"Righteous", sans-serif'}
            >
              Purpose
            </Typography>
          </Stack>
          <Stack
            direction="row"
            gap={1}
            sx={
              mobile
                ? {
                    width: "100%",
                    "& > *:first-child": {
                      flex: 1,
                    },
                  }
                : {}
            }
          >
            <Button
              size="lg"
              component={Link}
              to="/"
              variant="outlined"
              color="neutral"
              endDecorator={<IoIosReturnLeft size="1.3em" />}
              sx={(theme) => ({
                transition: "all ease .2s",
                position: "relative",
                borderRadius: "0",
                width: "fit-content",
                flexShrink: 0,
                padding: "1 2",
                background: theme.palette.background.body,
                "&:hover": {
                  // background: theme.palette.text.primary,
                  color: theme.palette.background.level1,
                  borderColor: theme.palette.text.primary,
                  "& > span > svg": {
                    transform: "translate(.6rem, .3rem) scale(1.2)",
                    filter: `drop-shadow(-.3rem -.3rem 0 ${theme.palette.text.tertiary}) drop-shadow(-.3rem -.3rem 0 ${theme.palette.text.secondary})`,
                  },
                },
                "&:active": {
                  transform: "scale(.98)",
                  "& > span > svg": {
                    transform: "translate(.6rem, .3rem) scale(1.1)",
                    filter: `drop-shadow(-.3rem -.3rem 0 ${theme.palette.background.level1}) drop-shadow(-.3rem -.3rem 0 ${theme.palette.background.level1})`,
                  },
                },
                "& > span > svg": {
                  transition: "all ease .2s",
                },
              })}
            >
              Back to homepage
            </Button>
          </Stack>

          <Box mt={2}>
            <Typography level="h5" sx={{ fontWeight: "bold" }}>
              Our Purpose
            </Typography>

            <Box
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                flexWrap: "wrap",
              }}
              gap={2}
            >
              <Typography>
                Rapid Automation Group (RAG) aims to provide cutting-edge
                solutions to business across C&H, helping them streamline their
                operations, increase productivity, and reduce costs. The Team
                recognized the growing demand for automation solutions and saw
                an opportunity to create a group that could deliver innovative
                and customizable solutions to meet the unique needs of each
                client. The team's commitment to innovation is evident in their
                continuous research and development efforts. RAG invests heavily
                in staying up to date with the latest advancements in automation
                technologies, ensuring that their clients benefit from the most
                cutting-edge solutions available.
              </Typography>
            </Box>

            <Typography mt={4} level="h5" sx={{ fontWeight: "bold" }}>
              Vision & Commitments
            </Typography>

            <Box
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                flexWrap: "wrap",
              }}
              gap={2}
            >
              <Typography>
                <strong>Vision: </strong>High impact delivery, building seamless
                integrated workflows, Unified intelligent automated eco-system.
                Enhancing client experience & future-proofing, empowering team
                with automation driven insights. Pioneering sustainable
                automation practices cultivating a culture of continuous
                improvement.
              </Typography>

              <Typography>
                <strong>Commitments: </strong>RAG's commitment to innovation,
                research, and development, positions it as the leader in
                transforming businesses through automation.
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Stack>
      <Credits />
    </Stack>
  );
}
