import { IconType } from "react-icons";
import { FaCodeBranch, FaCommentAlt, FaHeart, FaStar } from "react-icons/fa";
import { FiFigma, FiGithub } from "react-icons/fi";
import { SiResearchgate } from "react-icons/si";
import { TbBrandDeviantart } from "react-icons/tb";

export const platforms = ["github", "figma", "deviantart", "researchgate"] as const;
export type Platform = (typeof platforms)[number];

export const platformDetails: Record<
  Platform,
  {
    label: string;
    sublabel: string;
    icon: IconType;
  }
> = {
  github: {
    label: "GitHub",
    icon: FiGithub,
    sublabel: "Code repository",
  },
  figma: {
    label: "Figma",
    icon: FiFigma,
    sublabel: "Design prototype",
  },
  deviantart: {
    label: "DeviantArt",
    icon: TbBrandDeviantart,
    sublabel: "Artwork",
  },
  researchgate: {
    label: "ResearchGate",
    sublabel: "Research paper",
    icon: SiResearchgate,
  },
};

export const interactions = ["stars", "forks", "comments", "likes"] as const;
export type Interaction = (typeof interactions)[number];

export const interactionDetails: Record<Interaction, { label: string; icon: IconType }> = {
  stars: { label: "Stars", icon: FaStar },
  forks: { label: "Forks", icon: FaCodeBranch },
  comments: { label: "Comments", icon: FaCommentAlt },
  likes: { label: "Likes", icon: FaHeart },
};

export interface Project {
  title: string;
  description?: string;
  thumbnail?: string;
  source: string;
  demo?: string;
  language?: string;
  createdAt?: string;
  updatedAt?: string;
  interactions?: Partial<Record<Interaction, number>>;
  platform: Platform;
}

export interface ProjectCardNew {
  title: string;
  description?: string;
  demo?: string;
  language?: string;
}

export const projectsList: ProjectCardNew[] = [
  {
    title: "PSI / PAI App",
    description: "We have developed this Full Stack application for users, to fill out the survey based on their mappings of Tower, Process, Sub-process, region, etc for both As Is and To Be scenarios. To extract and understand the Survey data of users using Power BI Dashboards which would ingest data from the Full Stack application's DB.",
    // demo: "https://amaze.me/",
    language: "React, Python & SQL",
  },
  {
    title: "ORION",
    description: "A Tool for Boosting Process Efficiency. Designed by RAG, it provides automation solutions that eliminate manual efforts, streamlining workflows, optimizing time & increase productivity. It allows peers to focus on strategic tasks, improving overall operational efficiency through real-time production visibility.",
    // demo: "https://amaze.me/",
    language: "Power App & Power BI",
  },

  {
    title: "Visualization Projects",
    description: "Offering insights on GL close activities (C&H accounts)Smart narrative building (Key metrics. Trending, critical information, predictions, interactive visuals) Rich Foods, Mars, Pladis, Danone, Kraft Heinz, Tropicana, THS, & New transition accounts.",
    // demo: "https://amaze.me/",
    language: "Power BI, Tableau, Power Applications, Macro",
  },
  {
    title: "ORION E Master",
    description: "Orion e-Master facilitates the management of both shared and individual mailboxes by offering real-time monitoring capabilities. ORION e-master provides the feasibility to track the digital footprint of deleted emails. Facilitates the assignment of each email to specific user. Each email is associated with a real-time status and a designated assignee.",
    // demo: "https://amaze.me/",
    language: "Power App & Power BI",
  },
  {
    title: "ORION 2.0 (Allocation & production Management)",
    description: "A Tool for Boosting Process Efficiency. Designed by RAG, it provides automation solutions that eliminate manual efforts, streamlining workflows, optimizing time & increase productivity. It allows peers to focus on strategic tasks, improving overall operational efficiency through real-time production visibility.",
    demo: "https://genpactonline-my.sharepoint.com/:v:/g/personal/703372639_genpact_com/EXtWODe4VWZAgf5nTcCCDTsBuZCskHk9u4Bq9_EtK3Z2kw?e=nkMJTh",
    language: "Power App & Power BI",
  },
  {
    title: "GOL Repletter",
    description: "",
    // demo: "https://amaze.me/",
    language: "Power App",
  },
  {
    title: "Claim uploading",
    description: "Extract PDF file from Email preparing excel file using pdf after that start posting into Web Authrizer portal",
    // demo: "https://amaze.me/",
    language: "Python",
  },
];

const rankingCoefficients = {
  content: 0.5,
  thumbnail: 0.5,
  demo: 1,
  rarity: 2,
  interactions: 0.5,
};

/**
 * Rank a project based on its content, thumbnail, demo and rarity.
 */
export function rank(project: Project, catalog: Project[]): number {
  const contentLength = project.title.length + (project.description?.length || 0);
  const contentPertinence =
    contentLength > 150 ? rankingCoefficients.content - (contentLength - 150) / 350 : contentLength / 150;

  const hasThumbnail = project.thumbnail ? rankingCoefficients.thumbnail : 0;
  const hasDemo = project.demo ? rankingCoefficients.demo : 0;

  const minorityCoefficient =
    rankingCoefficients.rarity * (catalog.filter((item) => item.platform === project.platform).length / catalog.length);

  const interactionCoefficient =
    Object.values(project.interactions ?? {}).reduce((acc, value) => acc + value, 0) * rankingCoefficients.interactions;

  return contentPertinence + hasThumbnail + hasDemo + minorityCoefficient + interactionCoefficient;
}
