import React from "react";
import ReactDOM from "react-dom/client";
import { StyledEngineProvider } from "@mui/joy";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { SpeedInsights } from "@vercel/speed-insights/react";
import Landing from "Landing";
import ReactGA from "react-ga4";
import "App.global.scss";
import Projects from "components/projects/Projects";
import ThemeLoader from "components/ThemeLoader";
import NavigationBar from "navigation/NavigationBar";
import NotFound from "NotFound";
import RequestRequirements from "components/RequestRequirement";
import Capabilities from "./components/Capabilities";
import ContactUs from "components/ContactUs";
import Organization from "components/Organization";
import About from "components/about/About";

if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID as string);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <SpeedInsights />
    <StyledEngineProvider injectFirst>
        <ThemeLoader>
          <BrowserRouter>
            <Routes>
              <Route
                element={
                  <NavigationBar>
                    <Outlet />
                  </NavigationBar>
                }
              >
                <Route path="/" element={<Landing />} />
                <Route path="/about" element={<About />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/request-requirements" element={<RequestRequirements />} />
                <Route path="/capabilities" element={<Capabilities />} />
                <Route path="/org" element={<Organization />} />
                <Route path="/contact-us" element={<ContactUs />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </ThemeLoader>
    </StyledEngineProvider>
  </React.StrictMode>
);
