import React, { useState } from "react";
import { styled } from "@mui/joy/styles";
import Sheet from "@mui/joy/Sheet";
import { Button, Grid, Typography } from "@mui/joy";
import { FaMinus, FaPlus } from "react-icons/fa";

// Define your styled component
const StyledItem = styled(Sheet)(({ theme }) => ({
  fontWeight: theme.fontWeight.md,
  color: theme.vars.palette.text.secondary,
  border: "1px solid",
  borderColor: theme.palette.divider,
  padding: theme.spacing(1),
  borderRadius: theme.radius.md,
}));

const Item: React.FC<{ children: React.ReactNode; title: string }> = ({ children, title }) => {
  const [show, setShow] = useState(false);

  return (
    <StyledItem>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={8}>
          <Typography level="h3" ml={2} mt={1}>
            {title}
          </Typography>
        </Grid>
        <Grid xs={4} textAlign="end">
          <Button size="sm" variant="solid" onClick={() => setShow(!show)} sx={{ marginTop: "3%" }}>
            {show ? <FaMinus /> : <FaPlus />}
          </Button>
        </Grid>
      </Grid>
      <Grid xs={10} m={2}>
        {show && children}
      </Grid>
    </StyledItem>
  );
};

export default Item;
