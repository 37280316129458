import {
  Button,
  Stack,
  Typography,
  CssBaseline,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Input,
  Option,
} from "@mui/joy";
import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";

import React, { useState, useEffect } from "react";
import Meta from "./Meta";
import { useMobileMode } from "./Responsive";
import details from "assets/Details";
import { Link } from "react-router-dom";
import { IoIosReturnLeft } from "react-icons/io";
import Credits from "./sections/Credits";

type formState = {
  email: string;
  accountName: string;
  projectName: string;
  tower: string;
  process: string;
  currentHeadCount: number | undefined;
  currentLocation: string;
  requirementCategory: string;
  expectedOpportunity: string;
  cost: string;
};

export default function RequestRequirements() {
  const SERVICE_ID: string = "service_lbfup5p";
  const TEMPLATE_ID: string = "template_cjun8rm";
  const PUBLIC_KEY: string = "PbHn1ZjEm0wbHWa49";

  const [formState, setFormState] = useState<formState>({
    email: "",
    accountName: "",
    projectName: "",
    tower: "",
    process: "",
    currentHeadCount: undefined,
    currentLocation: "",
    requirementCategory: "",
    expectedOpportunity: "",
    cost: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name as string]: value as string,
    });
  };

  const handleSelectChange = (event: any, newValue: string | null) => {
    setFormState({
      ...formState,
      requirementCategory: newValue || "",
    });
  };

  useEffect(() => {
    let cost = "";
    switch (formState.requirementCategory) {
      case "Low":
        cost = "1000";
        break;
      case "Medium":
        cost = "5000";
        break;
      case "High":
        cost = "10000";
        break;
      default:
        cost = "";
    }
    setFormState((prevState) => ({
      ...prevState,
      cost: cost,
    }));
  }, [formState.requirementCategory]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formState);
    try {
      await emailjs.send(
        SERVICE_ID,
        TEMPLATE_ID,
        {...formState, to_name: 'RAG Team', },
        {
          publicKey: PUBLIC_KEY,
        }
      );
      console.log("SUCCESS!");
    } catch (err) {
      if (err instanceof EmailJSResponseStatus) {
        console.log("EMAILJS FAILED...", err);
        return;
      }

      console.log("ERROR", err);
    }
  };
  const mobile = useMobileMode();
  return (
    <Stack>
    <Stack
      width="100vw"
      position="relative"
      overflow="hidden"
      sx={{
        paddingLeft: "var(--nav-safe-area-inset-left)",
        paddingBottom: "var(--nav-safe-area-inset-bottom)",
      }}
    >
      <Meta title={`Request - ${details.name.first}`} />
      <Stack padding={mobile ? "1rem" : "5rem"} gap={2} alignItems="start">
        <Stack marginBottom="min(10vw, 3rem)" marginTop={mobile ? "10vw" : 0}>
          <Typography
            level="display1"
            fontSize="clamp(3rem, 15vw, 10rem)"
            fontWeight={200}
            lineHeight={1}
            sx={{ position: "relative", marginLeft: ".25em", marginBottom: ".05em" }}
          >
            <Typography
              level="h2"
              sx={{
                position: "absolute",
                left: "-.6em",
                bottom: "1.7em",
                transform: "translateX(-50%) rotate(-90deg)",
                fontSize: "clamp(.5rem, 3vw, 2rem)",
              }}
            >
              Form
            </Typography>
            Request
          </Typography>
          <Typography
            level="display1"
            fontSize="clamp(3rem, 15vw, 10rem)"
            fontWeight={300}
            lineHeight={0.6}
            zIndex={1}
            fontFamily={'"Righteous", sans-serif'}
          >
            Requirement
          </Typography>
        </Stack>
        <Stack
          direction="row"
          gap={1}
          sx={
            mobile
              ? {
                  width: "100%",
                  "& > *:first-child": {
                    flex: 1,
                  },
                }
              : {}
          }
        >
          <Button
            size="lg"
            component={Link}
            to="/"
            variant="outlined"
            color="neutral"
            endDecorator={<IoIosReturnLeft size="1.3em" />}
            sx={(theme) => ({
              transition: "all ease .2s",
              position: "relative",
              borderRadius: "0",
              width: "fit-content",
              flexShrink: 0,
              padding: "1 2",
              background: theme.palette.background.body,

              '&:hover': {
                // background: theme.palette.text.primary,
                color: theme.palette.background.level1,
                borderColor: theme.palette.text.primary,
                '& > span > svg': {
                  transform: 'translate(.6rem, .3rem) scale(1.2)',
                  filter: `drop-shadow(-.3rem -.3rem 0 ${theme.palette.text.tertiary}) drop-shadow(-.3rem -.3rem 0 ${theme.palette.text.secondary})`,
                },
              },
              '&:active': {
                transform: 'scale(.98)',
                '& > span > svg': {
                  transform: 'translate(.6rem, .3rem) scale(1.1)',
                  filter: `drop-shadow(-.3rem -.3rem 0 ${theme.palette.background.level1}) drop-shadow(-.3rem -.3rem 0 ${theme.palette.background.level1})`,
                },
              },
              '& > span > svg': {
                transition: 'all ease .2s',
              },
            })}
          >
            Back to homepage
          </Button>
        </Stack>

        <Box className="App">
          <Typography level="h6">Please fill out this form</Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: "flex", alignItems: "center", marginTop: "20px", flexWrap: "wrap" }}
            gap={2}
          >
            <Input
              placeholder="Email id"
              type="email"
              name="email"
              value={formState.email}
              onChange={handleInputChange}
              required
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <Input
              placeholder="Account Name"
              type="text"
              name="accountName"
              value={formState.accountName}
              onChange={handleInputChange}
              required
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <Input
              placeholder="Project Name"
              type="text"
              name="projectName"
              value={formState.projectName}
              onChange={handleInputChange}
              required
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <Input
              placeholder="Tower"
              type="text"
              name="tower"
              value={formState.tower}
              onChange={handleInputChange}
              required
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <Input
              placeholder="Process"
              type="text"
              name="process"
              value={formState.process}
              onChange={handleInputChange}
              required
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <Input
              placeholder="Current Head Count"
              type="number"
              name="currentHeadCount"
              value={formState.currentHeadCount}
              onChange={handleInputChange}
              required
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <Input
              placeholder="Current Location"
              type="text"
              name="currentLocation"
              value={formState.currentLocation}
              onChange={handleInputChange}
              required
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <FormControl sx={{ marginBottom: "20px", width: "300px" }}>
              <Select
                id="requirement-category-label"
                value={formState.requirementCategory}
                onChange={handleSelectChange}
              >
                <Option value="">
                  <em>Select a Requirement Category</em>
                </Option>
                <Option value="Low">Low</Option>
                <Option value="Medium">Medium</Option>
                <Option value="High">High</Option>
              </Select>
            </FormControl>
            <Input
              placeholder="Expected Opportunity"
              type="date"
              name="expectedOpportunity"
              value={formState.expectedOpportunity}
              onChange={handleInputChange}
              required
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <Input
              placeholder="Cost"
              type="text"
              name="cost"
              value={formState.cost}
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <Button type="submit" variant="outlined" color="primary">
              Submit
            </Button>
          </Box>
        </Box>
      </Stack>
      </Stack>
      <Credits/>
    </Stack>
  );
}
