import styled from "@emotion/styled";
import React, { useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import { Avatar, Chip, Divider, Grid, Stack } from "@mui/joy";
import Credits from "components/sections/Credits";

interface StyledNodeProps {
  photoURL?: string;
  name: string;
  designation?: string;
  department: boolean;
  children?: StyledNodeProps[];
  teamMembers?: string[];
  deptLead?: boolean;
}

const StyledNode: React.FC<StyledNodeProps> = ({ photoURL, name, designation, department, deptLead, teamMembers }) => {
  const AvatarComponent = () => {
    if (photoURL && photoURL !== "") {
      return (
        <Avatar>
          <img src={photoURL} />
        </Avatar>
      );
    } else if (department) {
      return null;
    } else if (deptLead) {
      return null;
    } else {
      const initials = name.split(" ").map((word) => {
        return word[0].toUpperCase();
      });
      return <Avatar>{initials}</Avatar>;
    }
  };

  const backgroundColor = deptLead ? "rgba(9, 107, 222, 0.36)" : "#fff";
  let width = "150px";
  if (teamMembers && teamMembers.length > 0) {
    width = "200px";
  }

  return (
    <Card variant="outlined" size="sm" sx={{ display: "inline-block", width: width, backgroundColor: backgroundColor }}>
      <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <AvatarComponent />
        {department ? (
          <Typography level="body3">{name.toUpperCase()}</Typography>
        ) : (
          <Typography level="body2" textColor="black">
            {name}
          </Typography>
        )}
        {designation && !department && <Typography level="body3">{designation}</Typography>}

        {teamMembers && teamMembers.length > 0 && (
          <Grid container mt={1}>
            {teamMembers.map((member) => {
              return (
                <Grid xs={12} display="flex" textAlign="left" flexDirection="column" pt={0.5}>
                  <Typography level="body2" textColor="black">
                    - {member}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

const data: StyledNodeProps = {
  photoURL: "",
  name: "Sandeep Das",
  designation: "GDA",
  department: false,
  children: [
    {
      photoURL: "",
      name: "Pradeep Sharma",
      designation: "AVP",
      department: false,
      children: [
        {
          name: "Analytics team",
          department: true,
          children: [
            {
              photoURL: "",
              name: "Vineet Gupta",
              designation: "Manager",
              department: false,
              deptLead: true,
              teamMembers: [
                "Tanuj Kumar",
                "Prashant Chawraia",
                "Ravi Kumar Sharma",
                "Sherry Jindal",
                "Raju Raju",
                "Om Prakash",
                "Jugraj Cheema",
                "Dushyant Rana",
              ],
            },
          ],
        },
        {
          name: "Tech team",
          department: true,
          children: [
            {
              photoURL: "",
              name: "Nitin Kumar",
              designation: "Manager",
              department: false,
              deptLead: true,
              teamMembers: [
                "Shivani Gupta",
                "Harshita Agarwal",
                "Deepanshu Verma",
                "Abhishek Chaudhary",
                "Rupesh Kumar",
                "Jai Prakash",
                "Muskan Sharma",
                "Monika Kumari",
              ],
            },
          ],
        },
      ],
    },
  ],
};

const OrgTree = () => (
  <div style={{ backgroundColor: "#F7F7F8" }}>
    <Tree
      lineWidth={"2px"}
      lineColor={"green"}
      lineBorderRadius={"10px"}
      label={<StyledNode photoURL={data.photoURL} name={data.name} department={false} designation={data.designation} />}
    >
      {data.children?.map((ragTeamMember) => {
        return (
          <TreeNode
            label={
              <StyledNode
                photoURL={ragTeamMember.photoURL}
                name={ragTeamMember.name}
                department={false}
                designation={ragTeamMember.designation}
              />
            }
          >
            {ragTeamMember.children &&
              ragTeamMember.children.map((ragTeamDept) => {
                return (
                  <TreeNode
                    label={
                      <StyledNode name={ragTeamDept.name} department={true} designation={ragTeamDept.designation} />
                    }
                  >
                    {ragTeamDept.children &&
                      ragTeamDept.children.map((deptLead) => {
                        return (
                          <TreeNode
                            label={
                              <StyledNode
                                name={deptLead.name}
                                department={false}
                                deptLead={true}
                                designation={deptLead.designation}
                                teamMembers={deptLead.teamMembers}
                              />
                            }
                          ></TreeNode>
                        );
                      })}
                  </TreeNode>
                );
              })}
          </TreeNode>
        );
      })}
    </Tree>
    </div>
);

export default OrgTree;
