const details = {
  name: {
    first: 'RAG',
    last: '',
    nickname: '',
  },
  
  
  contact: {
    address: 'Stellar Genpact, Noida, U.P',
    email: 'rag_team@genpact.com',
    phone: '+91-(9999356344)',
    website: 'https://www.genpact.com/RAG',
  },
};

export default details;
