import React, { useMemo } from "react";

import { Avatar, Button, Stack, Typography, useColorScheme } from "@mui/joy";
import { RiArrowRightSLine } from "react-icons/ri";
import { IoReaderOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import greetingFactory from "utils/Greeting";
import { ATypography } from "Landing";
import details from "assets/Details";
import { useMobileMode } from "../Responsive";

export const visitors = [
  "stranger",
  "collaborator",
  "developer",
  "human",
  "visitor",
  "friend",
];

export default function Header() {
  const mobile = useMobileMode();

  const visitor = useMemo(
    () => visitors[Math.floor(Math.random() * visitors.length)],
    []
  );
  const greeting = useMemo(greetingFactory, []);

  const { colorScheme } = useColorScheme();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dark = useMemo(() => colorScheme === "dark", [colorScheme]);

  return (
    <Stack
      gap="20px"
      alignItems={mobile ? "center" : "flex-start"}
      sx={(theme) => ({
        position: "relative",
        "&::before": mobile
          ? {}
          : {
              content: '""',
              position: "absolute",
              top: "calc(70px + 33px)",
              left: "-50px",
              height: "calc(100% + 80px - 70px - 33px)",
              width: "2px",
              background: `linear-gradient(to bottom, color-mix(in srgb, ${theme.palette.primary[500]}, ${theme.palette.background.body} 40%), ${theme.palette.primary[500]} 30%)`,
            },
      })}
    >
      <Button
        size="lg"
        component={Link}
        variant="outlined"
        to="/projects"
        startDecorator={
          <Avatar
            size="lg"
            color="primary"
            sx={(theme) => ({
              border: `1px solid rgba(${theme.palette.primary.mainChannel} / 0.4)`,
            })}
          >
            <IoReaderOutline />
          </Avatar>
        }
        endDecorator={<RiArrowRightSLine size="1.3em" />}
        sx={(theme) => ({
          "&:not(:hover)": {
            backgroundColor: `color-mix(in srgb, ${theme.palette.primary.softBg}, transparent 50%)`,
          },
          "&:hover, &:active": {
            boxShadow: `0 0 40px 5px rgba(${theme.vars.palette.primary.mainChannel} / 0.2)`,
            "& > span > svg": {
              transform: "translateX(.6rem) scale(1.2)",
              filter: `drop-shadow(-.3rem 0 0 rgba(${theme.vars.palette.primary.mainChannel} / 0.7)) drop-shadow(-.3rem 0 0 rgba(${theme.vars.palette.primary.mainChannel} / 0.7))`,
            },
          },
          "&:active > span > svg": {
            transform: "translateX(.6rem) scale(1.1)",
          },
          "& > span > svg": {
            transition: "all ease .2s",
          },
          position: "relative",
          borderColor: `color-mix(in srgb, ${theme.palette.primary[500]}, ${theme.palette.background.body} 40%)`,
          borderWidth: "2px",
          borderRadius: "100px",
          width: "fit-content",
          maxWidth: "100%",
          backdropFilter: "blur(5px)",
          transition: "all ease .2s",
          flexShrink: 0,
          padding: 1,
          paddingRight: 2,
          "&::before": mobile
            ? {}
            : {
                position: "absolute",
                content: '""',
                top: "50%",
                left: "-50px",
                width: "50px",
                height: "70px",
                marginLeft: "-2px",
                border: `2px solid color-mix(in srgb, ${theme.palette.primary[500]}, ${theme.palette.background.body} 40%)`,
                borderBottom: "none",
                borderRight: "none",
                borderTopLeftRadius: "50px",
              },
        })}
      >
        <Stack
          alignItems="flex-start"
          sx={{ textAlign: "left", minWidth: 0, flex: "1 1 100%" }}
        >
          <Typography
            level="body1"
            textColor="inherit"
            fontWeight="lg"
            lineHeight="1.2em"
            mt={-0.6}
          >
            Check out our projects
          </Typography>
        </Stack>
      </Button>
      <Typography
        level={mobile ? "h2" : "display2"}
        fontWeight="md"
        display="flex"
        justifyContent={mobile ? "center" : "initial"}
        textColor={dark ? "text.primary" : "#051825"}
        textAlign={mobile ? "center" : "initial"}
        fontFamily="'Fira Code', monospace"
        flexWrap="wrap"
      >
        {`${greeting}`}
      </Typography>
      <Typography
        level={mobile ? "h5" : "h5"}
        textColor={dark ? "text.tertiary" : "#3c617b"}
        sx={{
          position: "relative",
          textAlign: mobile ? "center" : "justify",
        }}
      >
        Hello, We're the{" "}
        <ATypography
          textColor="primary.plainColor"
          href={details.contact.website}
        >
          {`${details.name.first}`}
        </ATypography>{" "}
        Team . A team of innovative, result-driven professionals with proven
        experience in finance and accounting operations across all divisions,
        such as accounts payable, accounts receivable, record to report, order
        management, and financial planning and analysis.  The team is
        technologically expert at automating tasks and optimizing processes
        within existing work models. They are also skilled in creating new
        workflows for manual or non-traditional tasks to streamline operations.
        
      </Typography>
    </Stack>
  );
}
