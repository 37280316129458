import React from "react";
import { Box, Typography, Button, List, ListItem, Grid } from "@mui/joy";
import { Link } from "react-router-dom";
import { useMobileMode } from "components/Responsive";
import { GoLocation } from "react-icons/go";
import { BsMailbox, BsPhone } from "react-icons/bs";

const Footer = () => {
  const mobile = useMobileMode();
  const capabilitiesList = [
    "Analytics",
    "Automation",
    "Business Intelligence",
    "Data Automation",
    "Data Management & Transformation",
    "Development based on Microsoft Platform ",
    "Web App Development",
  ];

  const clients = ["Carlsberg", "Mars", "Mondelez International Pvt. Ltd.", "Unilever", "Tropicana", "Haleon", "Pladis", "Rich Food"];
  const leftMargin = 0

  return (
    <Box component="footer" sx={{ backgroundColor: "#00AECF", color: "#fff", p: 4, ml: leftMargin }}>
      <Grid container sx={{ margin: "0 auto", flex: "1 auto" }}>
        <Grid xs={12} md={3}>
          <Typography level="h1" fontSize="lg" sx={{ fontWeight: "bold", color: "#000" }}>
            Rapid Automation Group
          </Typography>
          <Typography
            level="body2"
            sx={{
              fontWeight: "bold",
              color: "#fff",
            }}
          >
            <GoLocation />
            {"   "} 2nd Floor, Tower 5,
            <br /> Genpact, Candor Techspace, <br />
            Sector 137, Noida, Uttar Pradesh, <br />
            120000
            <br />
            <br />
            <BsPhone /> +91-9999356344
            <br />
            <br />
            <BsMailbox /> rag_team@genpact.com
          </Typography>
        </Grid>

        <Grid xs={12} md={3}>
          <Typography level="h2" fontSize="lg" sx={{ fontWeight: "bold", color: "#000" }}>
            Our Capabilities
          </Typography>
          <List sx={{ listStyle: "none", padding: 0 }}>
            {capabilitiesList.map((item) => (
              <ListItem key={item}>
                <Typography
                  level="body2"
                  sx={{
                    fontWeight: "bold",
                    color: "#fff",
                  }}
                >
                  {item}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid xs={12} md={3}>
          <Typography level="h2" fontSize="lg" sx={{ fontWeight: "bold", color: "#000" }}>
            Businesses
          </Typography>
          <List sx={{ listStyle: "none", padding: 0 }}>
            {clients.map((business) => (
              <ListItem key={business}>
                <Typography
                  level="body2"
                  sx={{
                    fontWeight: "bold",
                    color: "#fff",
                  }}
                >
                  {business}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid xs={12} md={3}>
          <Typography
            level="h2"
            fontSize="lg"
            mb ={2}
            sx={{
              fontWeight: "bold",
              color: "#000",
            }}
          >
            Reach out to us so we can guide you
          </Typography>
          <Button component={Link} to="/contact-us">
            Write to us
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
